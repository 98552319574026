<template>
  <section>
    <breadcrumb :title="$t('balances')" :subtitle="$t('agency_balance')"/>
    <div class="card px-10 pb-30 rounded-10">
      <div class="columns filters">
        <div class="column is-flex is-align-items-center">
          <div>
            <b-field>
              <b-input :placeholder="$t('search')"
                       custom-class="search-filter"
                       v-model="search"
                       type="search"
                       icon="magnify"
                       icon-clickable
                       @icon-click="searchIconClick"
              >
              </b-input>
            </b-field>
          </div>
          <div class="ml-15">
            <b-icon type="is-pink-light" size="is-medium" icon="close-circle-outline"
                    class="mx-4 cursor-pointer"></b-icon>
          </div>
          <div @click="filter=!filter" class="ml-25 cursor-pointer">
            <b-icon type="is-purple" size="is-medium" :icon="filter_name"></b-icon>
          </div>
        </div>
      </div>
      <template v-if="filter">
        <div class="columns">
          <div class="column is-6">
            <div class="is-flex is-justify-content-space-between">
              <b-field>
                <b-input :placeholder="$t('name')"
                         v-model="query.name"
                         custom-class="custom-search"
                         icon-clickable
                >
                </b-input>
              </b-field>
              <b-field>
                <b-input :placeholder="$t('email')"
                         v-model="query.email"
                         custom-class="custom-search"
                         icon-clickable
                >
                </b-input>
              </b-field>
            </div>
          </div>
          <div class="column is-6">
            <div class="is-flex is-justify-content-space-between">
              <b-field>
                <b-input :placeholder="$t('phone')"
                         v-model="query.phone"
                         custom-class="custom-search"
                         icon-clickable
                >
                </b-input>
              </b-field>
              <b-field>
                <b-input :placeholder="$t('state')"
                         v-model="query.state"
                         custom-class="custom-search"
                         icon-clickable
                >
                </b-input>
              </b-field>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-6">
            <div class="is-flex is-justify-content-space-between">
              <b-field>
                <b-input :placeholder="$t('agents')"
                         v-model="query.agent"
                         custom-class="custom-search"
                         icon-clickable
                >
                </b-input>
              </b-field>
              <b-field>
                <b-input :placeholder="$t('payment_groups')"
                         v-model="query.pg"
                         custom-class="custom-search"
                         icon-clickable
                >
                </b-input>
              </b-field>
            </div>
          </div>
          <div class="column is-6">
            <div class="is-flex is-justify-content-flex-end">
              <b-button @click="getQueryFilter" class="btn btn-search" type="is-primary">
                {{ $t('search') }}
              </b-button>
            </div>
          </div>
        </div>
      </template>
    </div>

    <section class="pt-40">
      <b-table
          :data="desserts.data"
          ref="table"
          :paginated="false"
          per-page="per_page"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page">

        <b-table-column field="name" :label="$t('name')" v-slot="props">
            <span class="col name">
              {{ props.row.name }}
            </span>
        </b-table-column>
        <b-table-column field="email" :label="$t('email')" v-slot="props">
        <span class="col email">
          {{ props.row.email }}
        </span>
        </b-table-column>

        <b-table-column field="active" :label="$t('active')" v-slot="props">
          <div class="data-check">
            <b-checkbox type="is-success" v-model="props.row.isActive"></b-checkbox>
          </div>
        </b-table-column>
        <b-table-column field="agents" :label="$t('agents')" v-slot="props">
        <span class="col agents">
          {{ props.row.totalAgents }}
        </span>
        </b-table-column>

        <b-table-column field="payment_groups" :label="$t('payment_groups')" v-slot="props">
        <span class="col payment-groups" v-for="(item, idx) in props.row.paymentGroups"
              :key="idx"
        >
          <li>{{ item.groupName }}-{{ item.currencyFrom }}-{{ item.currencyTo }}</li>
        </span>
        </b-table-column>

        <b-table-column field="agents" :label="$t('total_usd')" v-slot="props">
        <span class="col common">
          {{ getAmount(props.row.paymentGroups, CURRENCY.USD) }}
        </span>
        </b-table-column>
        <b-table-column field="agents" :label="$t('total_cup')" v-slot="props">
        <span class="col common">
           {{ getAmount(props.row.paymentGroups, CURRENCY.CUP) }}
        </span>
        </b-table-column>
        <b-table-column field="agents" :label="$t('total_mlc')" v-slot="props">
        <span class="col common">
           {{ getAmount(props.row.paymentGroups, CURRENCY.MLC) }}
        </span>
        </b-table-column>

        <b-table-column :label="$t('actions')" v-slot="props">
          <div class="is-flex">
            <div class="mx-10" @click="setModalsAdd(props.row, balances_type.PLUS)">
              <b-tooltip type="is-primary" :label="$t('balance_plus')">
                <b-icon type="is-primary" icon="database-plus"
                        class="mx-4 cursor-pointer"></b-icon>
              </b-tooltip>
            </div>
            <div class="mx-10" @click="setModalsAdd(props.row, balances_type.MINUS)">
              <b-tooltip type="is-danger" :label="$t('balance_minus')">
                <b-icon type="is-danger" icon="database-minus"
                        class="mx-4 cursor-pointer"></b-icon>
              </b-tooltip>
            </div>
          </div>
        </b-table-column>
      </b-table>
      <paginate
          :count="desserts.totalRecords"
          :per_page="desserts.itemsPerPage"
          :current-page="desserts.currentPage"
          :total-pages="desserts.totalPages"
          @first="getLast(true)"
          @previous="getAgencies(desserts.currentPage - 1,desserts.itemsPerPage)"
          @next="getAgencies(desserts.currentPage + 1,desserts.itemsPerPage)"
          @last="getLast"
          @perPage="getAgencies(desserts.currentPage,$event)"
          @search="getAgencies($event,desserts.itemsPerPage)"
      />
      <b-modal
          v-model="isModalAgencyBalanceActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="false"
          aria-role="dialog"
          aria-label="Example Modal"
          aria-modal>
        <template #default="props">
          <modal-add-balance @reload="getAgencies(desserts.currentPage ,desserts.itemsPerPage)" :customer_type="customer_type" :object="agency" :type="type"
                             @close="props.close"></modal-add-balance>
        </template>
      </b-modal>
    </section>
  </section>
</template>

<script>
import Paginate from "@/components/list/paginate";
import Breadcrumb from "@/components/structure/breadcrumb";
import {mapActions} from "vuex";
import ModalAddBalance from "@/components/modals/modalAddBalance";
import {CURRENCY} from "@/enum/currency";
import {BALANCE_TYPE} from "@/enum/balancesType";
import {DEPOSIT_TYPE} from "@/enum/depositType";

export default {
  name: "AgencyBalanceComponent",
  components: {ModalAddBalance, Breadcrumb, Paginate},
  data() {
    return {
      CURRENCY,
      search: null,
      filter_name: 'filter-outline',
      filter: false,
      balances_type: BALANCE_TYPE,
      type: null,
      customer_type: null,
      isModalEditAgencyBalanceActive: false,
      isModalAgencyBalanceActive: false,
      propsModals: {},
      per_page: 5,
      agency: null,
      desserts: null,
      query: {
        name: null,
        email: null,
        phone: null,
        state: null,
        pg: null,
        agent: null,
      }
    }
  },
  methods: {
    ...mapActions({
      fetchAgencies: 'FETCH_AGENCY'
    }),
    setModalsAdd(props, operation) {
      this.agency = props;
      this.type = operation
      this.customer_type = DEPOSIT_TYPE.AGENCY
      this.isModalAgencyBalanceActive = !this.isModalAgencyBalanceActive
    },
    getAmount(list, currency) {
      let temp = list.find(a => a.currencyTo === currency)
      if (temp) {
        return temp.amount
      }
      return 0
    },
    reload() {
      window.location.reload()
    },
    getLast(isFirst = false) {
      let page = isFirst ? 1 : this.desserts.totalPages
      this.getAgencies(page, this.desserts.itemsPerPage)
    },
    getAgencies(current, record, id = null, query = null) {
      let data = {
        currentPage: current,
        recordsPerPage: record,
        id: id,
        query: query
      };
      this.fetchAgencies(data)
          .then(value => {
            this.desserts = value
          })
    },
    getQueryFilter() {
      let query = ''
      let prefix = '&'
      if (this.query.name) {
        query += `${prefix}name=${this.query.name}`
      }
      if (this.query.email) {
        query += `${prefix}email=${this.query.email}`
      }
      if (this.query.phone) {
        query += `${prefix}phonenumber=${this.query.phone}`
      }
      if (this.query.agent) {
        query += `${prefix}agentName=${this.query.agent}`
      }
      if (this.query.state) {
        query += `${prefix}state=${this.query.state}`
      }
      if (this.query.pg) {
        query += `${prefix}paymentGroupName=${this.query.pg}`
      }
      if (query.length) {
        this.getAgencies(1, 10, null, query)
      } else {
        this.getAgencies(1, 10, null, null)
      }
    },
    searchIconClick() {
      let query = this.search ? `&name=${this.search}` : null
      this.getAgencies(1, 10, null, query)
    },
  },
  computed: {},
  watch: {
    search:function () {
      if (!this.search){
        this.getAgencies(1, 10)
      }
    },
    filter: function () {
      this.filter_name = this.filter ? 'filter-remove-outline' : 'filter-outline'
    }

  },
  created() {
    this.getAgencies(1, 10)
  }
}
</script>

<style scoped lang="scss">
.col {
  line-height: 32px;
  letter-spacing: 0.5px;

  &.name {
    font-weight: bold;
    font-size: 16px;
    color: #666BAA;
  }


  &.payment-groups {
    font-weight: 800;
    font-size: 15px;
    line-height: 20px;
    color: #289BA4;
  }

  &.agents {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #0B2B46;
  }

  &.email {
    font-weight: normal;
    font-size: 16px;
    color: #0B2B46;
  }

  &.phone {
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    color: #0B2B46;
  }

}

</style>
